import { reactive } from "vue";
import householdData from "../../data/dsnap-data";

function getMaxHouseholdSize() {
  const maxHouseholdSize = Math.max(...householdData.households.map((h) => h.size));
  return maxHouseholdSize;
}

function getHouseholdInformation(householdSize) {
  const maxHouseholdSize = getMaxHouseholdSize();
  const searchSize = householdSize > maxHouseholdSize ? maxHouseholdSize : householdSize;
  const householdInfo = householdData.households.find((hh) => hh.size === searchSize);

  const overage = householdSize - maxHouseholdSize;
  if (overage > 0) {
    householdInfo.incomeLimit += overage * householdData.additionalMemberIncomeLimit;
    householdInfo.maximumAllotment += overage * householdData.maximumAllotment;
  }

  return householdInfo;
}

function calculateTotalIncome(income, resources) {
  if (income !== "" && resources !== "") {
    const totalIncome = Number(income) + Number(resources);

    return totalIncome;
  }
  return 0;
}

function calculateAdjustedIncome(expenses, totalIncome) {
  if (expenses !== "") {
    let adjustedIncome = totalIncome - Number(expenses);

    if (adjustedIncome < 0) {
      adjustedIncome = 0;
    }

    return adjustedIncome;
  }
  return 0;
}

function calculateIncomeLimit(householdSize) {
  if (householdSize !== "" && Number(householdSize) > 0) {
    const householdInfo = getHouseholdInformation(Number(householdSize));
    return {
      incomeLimit: householdInfo.incomeLimit,
      maximumAllotment: householdInfo.maximumAllotment,
    };
  }
  return {
    incomeLimit: 0,
    maximumAllotment: 0,
  };
}

function calculateEligibility(household, estimate) {
  const hh = household;
  if (hh.income !== "" && hh.resources !== "" && hh.expenses !== "" && hh.householdSize !== "") {
    return {
      isEligible: estimate.adjustedIncome <= estimate.incomeLimit,
      isEligibilityCalculated: true,
    };
  }
  return {
    isEligible: false,
    isEligibilityCalculated: false,
  };
}

export default function dsnapBenefitEstimator(household) {
  const estimate = reactive({
    income: 0,
    resources: 0,
    expenses: 0,
    householdSize: 0,
    totalIncome: 0,
    adjustedIncome: 0,
    incomeLimit: 0,
    maximumAllotment: 0,
    isEligible: false,
    isEligibilityCalculated: false,
  });

  const estimateBenefits = () => {
    estimate.income = household.income;
    estimate.resources = household.resources;
    estimate.expenses = household.expenses;
    estimate.householdSize = household.householdSize;

    const totalIncome = calculateTotalIncome(household.income, household.resources);
    const adjustedIncome = calculateAdjustedIncome(household.expenses, totalIncome);
    const { incomeLimit, maximumAllotment } = calculateIncomeLimit(household.householdSize);

    estimate.totalIncome = totalIncome;
    estimate.adjustedIncome = adjustedIncome;
    estimate.incomeLimit = incomeLimit;
    estimate.maximumAllotment = maximumAllotment;

    const { isEligible, isEligibilityCalculated } = calculateEligibility(household, estimate);

    estimate.isEligible = isEligible;
    estimate.isEligibilityCalculated = isEligibilityCalculated;
  };

  return {
    estimate,
    estimateBenefits,
  };
}
