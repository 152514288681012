const householdData = {
  additionalMemberIncomeLimit: 379,
  additionalMemberAllotment: 188,
  households: [
    {
      size: 1,
      incomeLimit: 1848,
      maximumAllotment: 250,
    },
    {
      size: 2,
      incomeLimit: 2226,
      maximumAllotment: 459,
    },
    {
      size: 3,
      incomeLimit: 2604,
      maximumAllotment: 658,
    },
    {
      size: 4,
      incomeLimit: 2990,
      maximumAllotment: 835,
    },
    {
      size: 5,
      incomeLimit: 3399,
      maximumAllotment: 992,
    },
    {
      size: 6,
      incomeLimit: 3808,
      maximumAllotment: 1190,
    },
    {
      size: 7,
      incomeLimit: 4187,
      maximumAllotment: 1316,
    },
    {
      size: 8,
      incomeLimit: 4565,
      maximumAllotment: 1504,
    },
  ],
};

export default householdData;
