import { createI18n } from "vue-i18n";

const messages = {
  en: {
    routes: {
      home: "Home",
      dsnap: "DSNAP",
      moreInfo: "More Info",
      nonDiscrimination: "Non-Discrimination Statement",
    },
    shared: {
      appTitle: "Benefits Estimator",
      menuTitle: "Menu",
      activeEvent: "false",
      eventName: "Hurricane Matt",
      eventStartDate: "01/01/2020",
      eventEndDate: "01/31/2020",
      eventMessage: "The Disaster Period for @:shared.eventName is from @:shared.eventStartDate to @:shared.eventEndDate .",
    },
    govBanner: {
      officialSite: "An official Louisiana.gov website.",
      officialSiteHow: "How do I know?",
      govOfficial: "The .gov means it's official.",
      govOfficialMeaning:
        "Louisiana government websites often end in .gov. Before sharing sensitive information, make sure you're on a Louisiana government site.",
      siteSecure: "The site is secure.",
      siteSecureMeaning:
        "The https:// ensures that you are connecting to the official website and that any information you provide is encrypted and transmitted securely.",
    },
    homePage: {
      title: "Benefits Estimator",
      subtitle: "Estimation tools for Benefits. Get started estimating your DSNAP benefits.",
      getStarted: "Get Started",
      getStartedBody:
        "These benefit estimators are estimations only and are not a guarantee to receive benefits. You must submit an application for the specified program in order to have your eligibility determined. Households must meet certain tests, including resource and income tests in order to be eligible for program benefits.",
      dsnapButtonText: "Estimate DSNAP Benefits",
    },
    dsnapPage: {
      title: "DSNAP Benefit Estimator",
      subtitle:
        "DSNAP provides eligible households, who do not normally receive SNAP benefits, with money to help buy groceries due to lost income or damages following a disaster.",
      householdTitle: "Household Information",
      incomeInputLabel: "Income",
      incomeInputDescription:
        "Provide the take-home income you received or expect to receive during the 30-day disaster benefit period. Your take-home income includes wages from work (including self-employment) and income from other sources, such as public assistance programs.",
      incomeValidationRequired: "Income is required.",
      incomeValidationMoreThan: "Income is required.",
      incomeHelpText:
        "Your take-home wages are the money that you and your family actually receive or expect to receive, after automatic deductions for things such as taxes, insurance premiums, retirement contributions, etc. In other words, your take-home wages are the amount deposited into your bank account or printed on your check.",
      resourcesInputLabel: "Accessible Resources",
      resourcesInputDescription:
        "Provide the amount of money you have available to you now, including cash on hand and funds in your checking or savings accounts, money market accounts, etc. Do NOT include money in your retirement accounts.",
      resourcesValidationRequired: "Resources are required.",
      resourcesValidationMoreThan: "Resources are required.",
      expensesInputLabel: "Disaster-Related Expenses",
      expensesInputDescription:
        "Provide the amount that you have spent or expect to spend on un-reimbursed disaster-related expenses during the 30-day disaster benefit period.",
      expensesValidationRequired: "Expenses are required.",
      expensesValidationMoreThan: "Expenses are required.",
      expensesHelpText:
        "Examples of disaster-related expenses can include home or business repairs, temporary shelter expenses, evacuation expenses, expenses related to replacing necessary personal and household items such as clothing, appliances, tools, fuel for primary heating source, clean-up items expense, disaster-damaged vehicle expenses, food lost to the disaster or power outages, storage and moving expenses paid during 30-day time period, etc. Items such as mortgage, rent, car notes, etc. that are recurring expenses do not count as a disaster-related expense.",
      householdInputLabel: "Household Size",
      householdInputDescription:
        'A "household" is the number of people you live with who purchase and prepare meals together with you.',
      householdValidationRequired: "Household size is required.",
      householdValidationMoreThan: "Household size is required and must be greater than 0.",
      calculateButton: "Calculate Estimate",
      recalculateButton: "Recalculate Estimate",
      benefitEstimateTitle: "Benefit Estimate",
      eligibilityStatement:
        "Eligibility for DSNAP benefits look at your income, resources, and disaster-related expenses during the designated 30-day disaster time-period. First, we count your take-home pay (from work and other sources such as Child Support, alimony, unemployment benefits, Social Security benefits, SSI benefits, VA benefits, FITAP benefits, worker's compensation, pensions, contributions from friends and/or family, etc.). Then, we add your available cash resources (including checking and savings balances) and subtract the total dollar amount of money you spent on any disaster-related expense - that has not already been reimbursed - during the designated 30-day period.",
      estimateDisclaimer:
        "Please Note: This is an estimate based only on the information provided below. This is not a guarantee to receive benefits or of the amount you may receive if determined that you are eligible after applying for DSNAP.",
      householdWarningMessage:
        "Please fill in all of the household information to calculate your estimated benefits.",
      ineligibleMessage:
        "You are <strong>likely ineligible</strong> for DSNAP benefits based on your <strong>adjusted income exceeding the disaster period gross income limit</strong>.",
      eligibleMessage:
        "You are <strong>likely eligible</strong> for DSNAP benefits with an estimated allotment of <strong>{maxAllotment}</strong>.",
      visitDsnapMessage:
        'Please <a href="http://www.dcfs.la.gov/DSNAP" target="_blank"> visit DSNAP</a> to pre-register online.',
      resultTitle: "How did I get this result?",
      resultStepOneMessage:
        "First, we calculated your total income by adding the income you provided, <strong>{income}</strong> with the resources you have access to, <strong>{resources}</strong> to arrive at a total income amount of <strong>{totalIncome}</strong>.",
      resultStepTwoMessage:
        "Then, we calculated your adjusted income by subtracting the un-reimbursed disaster-related expenses, <strong>{expenses}</strong> from your total income of <strong>{totalIncome}</strong> to calculate your adjusted income amount of <strong>{adjustedIncome}</strong>.",
      resultEligibleMessage:
        "Finally, we compared your adjusted income, <strong> {adjustedIncome}</strong> to the income limit for a household size of <strong>{householdSize} people</strong> which is <strong>{incomeLimit}</strong >. Because your adjusted income is less than the income limit for your household size the maximum allotment for your household is <strong>{maxAllotment}</strong>.",
      resultIneligibleMessage:
        "Finally, we compare your adjusted income, <strong>{adjustedIncome}</strong> to the income limit for a household size of <strong>{householdSize} people</strong> which is <strong>{incomeLimit}</strong>. Because your adjusted income exceeds the gross income limit for the disaster, that is why we estimate that you are <strong>likely ineligible</strong>.",
    },
  },
  es: {
    routes: {
      home: "Página de Inicio",
      dsnap: "DSNAP",
      moreInfo: "Más Información",
      nonDiscrimination: "Declaración de No Discriminación",
    },
    shared: {
      appTitle: "Estimador de Beneficios",
      menuTitle: "Menú",
      eventName: "Hurricane Matt",
      eventStartDate: "01/01/2020",
      eventEndDate: "01/31/2020",
      eventMessage: "El Período de Desastre para @:shared.eventName es desde @:shared.eventStartDate hasta @:shared.eventEndDate .",
    },
    govBanner: {
      officialSite: "Un sitio web oficial de Louisiana.gov.",
      officialSiteHow: "¿Como lo sé?",
      govOfficial: "El .gov significa que es oficial.",
      govOfficialMeaning:
        "Los sitios web del gobierno de Luisiana frecuentemente finalizan en .gov. Antes de compartir información confidencial, asegúrese de estar en un sitio del gobierno de Luisiana.",
      siteSecure: "El sitio es seguro.",
      siteSecureMeaning:
        "El https:// garantiza que se está conectando al sitio web oficial y que cualquier información que proporcione está encriptada y enviada de forma segura.",
    },
    homePage: {
      title: "Estimador de Beneficios",
      subtitle:
        "Herramientas de estimación de Beneficios. Comience a estimar sus beneficios del DSNAP.",
      getStarted: "Empezar",
      getStartedBody:
        "Los presentes estimadores de beneficios son solo estimaciones y no garantizan la recepción de algún beneficio. Debe enviar una solicitud para el programa en particular para que se determine su elegibilidad. Los hogares deben cumplir con ciertos criterios, incluyendo los de recursos e ingresos, para ser elegibles para los beneficios del programa.",
      dsnapButtonText: "Estimar los Beneficios del DSNAP",
    },
    dsnapPage: {
      title: "Estimador de Beneficios del DSNAP",
      subtitle:
        "El DSNAP proporciona a los hogares que reúnen los requisitos, y que normalmente no reciben beneficios del SNAP, dinero para ayudar a comprar alimentos debido a la pérdida de ingresos o a los daños causados luego de un desastre.",
      householdTitle: "Información del Hogar",
      incomeInputLabel: "Ingreso",
      incomeInputDescription:
        "Indique el ingreso neto que recibió o espera recibir durante el período del beneficio en caso de desastres de 30 días. Su ingreso neto incluye su salario (incluyendo el trabajo autónomo) y los ingresos de otras fuentes, como los programas de asistencia pública.",
      incomeValidationRequired: "Se requieren ingresos.",
      incomeValidationMoreThan: "Se requieren ingresos.",
      incomeHelpText:
        "Su salario neto es el dinero que usted y su familia realmente reciben o esperan recibir, luego de las deducciones automáticas por conceptos como impuestos, primas de seguro, contribuciones para el retiro, etc. En otras palabras, su salario neto es la cantidad depositada en su cuenta bancaria o escrita en su cheque.",
      resourcesInputLabel: "Recursos Accesibles",
      resourcesInputDescription:
        "Indique la cantidad de dinero de la que dispone actualmente, incluyendo el efectivo en mano y los fondos en sus cuentas corrientes o de ahorro, cuentas de mercado monetario, etc. NO incluya dinero en sus cuentas de retiro.",
      resourcesValidationRequired: "Se requieren recursos.",
      resourcesValidationMoreThan: "Se requieren recursos.",
      expensesInputLabel: "Gastos Relacionados con el Desastre",
      expensesInputDescription:
        "Indique la cantidad que ha gastado o espera gastar en gastos no reembolsados relacionados con el desastre durante el período del beneficio en caso de desastres de 30 días.",
      expensesValidationRequired: "Se requieren gastos.",
      expensesValidationMoreThan: "Se requieren gastos.",
      expensesHelpText:
        "Algunos ejemplos de gastos relacionados con desastres pueden incluir reparaciones del hogar o del negocio, gastos de refugio temporal, gastos de evacuación, gastos por el reemplazo de artículos personales y domésticos necesarios, como ropa, electrodomésticos, herramientas, combustible para la fuente de calefacción principal, gastos por artículos de limpieza, gastos por vehículos dañados por el desastre, alimentos perdidos por el desastre o por interrupciones de energía, gastos por almacenamiento y mudanza pagados durante un período de 30 días, etc. Asuntos como hipoteca, renta, pagarés de vehículos, etc., que son gastos recurrentes, no cuentan como gastos relacionados con el desastre.",
      householdInputLabel: "Tamaño del Hogar",
      householdInputDescription:
        'Un "hogar" es el número de personas con las que vive y que compran y preparan comidas junto con usted.',
      householdValidationRequired: "Se requiere el tamaño del hogar.",
      householdValidationMoreThan: "El tamaño del hogar es obligatorio y debe ser superior a 0.",
      calculateButton: "Calcular Estimación",
      recalculateButton: "Recalcular Estimación",
      benefitEstimateTitle: "Estimación de Beneficios",
      eligibilityStatement:
        "La elegibilidad para los beneficios del DSNAP tiene en cuenta los ingresos, recursos y gastos relacionados con el desastre durante el período de desastre de 30 días designado. Primero, consideramos su salario neto (procedente del trabajo y otras fuentes, como manutención infantil, pensión alimenticia, beneficios de desempleo, beneficios del Seguro Social, beneficios del SSI, beneficios de VA, beneficios de la FITAP, indemnización por accidentes de trabajo, pensiones, contribuciones de amigos y/o familiares, etc.). Luego, sumamos sus recursos en efectivo disponibles (incluyendo los saldos en cuentas corrientes y de ahorro) y restamos la cantidad total de dinero que gastó en cualquier gasto relacionado con el desastre, que aún no haya sido reembolsado, durante el período de 30 días designado.",
      estimateDisclaimer:
        "Por favor, tenga en cuenta: Esta es una estimación basada únicamente en la información proporcionada a continuación. Esto no es una garantía para recibir beneficios o la cantidad que puede recibir si se determina que es elegible luego de enviar una solicitud para el DSNAP.",
      householdWarningMessage:
        "Complete toda la información del hogar para calcular sus beneficios estimados.",
      ineligibleMessage:
        "Es <strong>probable que no sea elegible</strong> para los beneficios del DSNAP debido a que <strong>sus ingresos ajustados superan el límite de ingresos brutos del período de desastre</strong>.",
      eligibleMessage:
        "Es <strong>probable que sea elegible</strong> para los beneficios del DSNAP con una asignación estimada de <strong>{maxAllotment}</strong>.",
      visitDsnapMessage:
        'Por favor, <a href="http://www.dcfs.la.gov/DSNAP" target="_blank">visite el DSNAP</a> para preinscribirse en línea.',
      resultTitle: "¿Cómo obtuve este resultado?",
      resultStepOneMessage:
        "Primero, calculamos su ingreso total sumando los ingresos que proporcionó, <strong>{income}</strong> con los recursos a los que tiene acceso, <strong>{resources}</strong> para llegar a una suma total de ingresos de <strong>{totalIncome}</strong>.",
      resultStepTwoMessage:
        "Luego, calculamos su ingreso ajustado restando los gastos no reembolsados relacionados con el desastre, <strong>{expenses}</strong> de su ingreso total de <strong>{totalIncome}</strong> para calcular la suma de su ingreso ajustado de <strong>{adjustedIncome}</strong>.",
      resultEligibleMessage:
        "Finalmente, comparamos su ingreso ajustado, <strong>{adjustedIncome}</strong> con el límite de ingresos para un tamaño de hogar de <strong>{householdSize} personas</strong>, que es <strong>{incomeLimit}</strong>. Debido a que su ingreso ajustado es menor que el límite de ingresos para el tamaño de su hogar, la asignación máxima para su hogar es <strong>{maxAllotment}</strong>.",
      resultIneligibleMessage:
        "Finalmente, comparamos su ingreso ajustado, <strong>{adjustedIncome}</strong> con el límite de ingresos para un tamaño de hogar de <strong>{householdSize} personas</strong>, que es <strong>{incomeLimit}</strong>. Debido a que su ingreso ajustado excede el límite de ingreso bruto para el desastre, es por eso que estimamos que <strong>probablemente no sea elegible</strong>.",
    },
  },
  vi: {
    routes: {
      home: "Trang chủ",
      dsnap: "DSNAP",
      moreInfo: "Thêm thông tin",
      nonDiscrimination: "Tuyên bố Không phân biệt đối xử",
    },
    govBanner: {
      officialSite: "Một trang web chính thức của Louisiana.gov.",
      officialSiteHow: "Làm sao tôi biết?",
      govOfficial: "Đuôi .Gov có nghĩa là nó chính thức.",
      govOfficialMeaning:
        "Các trang web của chính phủ Louisiana thường kết thúc bằng .gov. Trước khi chia sẻ thông tin nhạy cảm, hãy đảm bảo rằng bạn đang ở trên trang web của chính phủ Louisiana.",
      siteSecure: "Trang web được bảo mật.",
      siteSecureMeaning:
        "Https: // đảm bảo rằng bạn đang kết nối với trang web chính thức và mọi thông tin bạn cung cấp đều được mã hóa và truyền đi một cách an toàn.",
    },
    shared: {
      appTitle: "Công cụ ước tính phúc lợi",
      menuTitle: "Menu",
      eventName: "Hurricane Matt",
      eventStartDate: "01/01/2020",
      eventEndDate: "01/31/2020",
      eventMessage: "Thời kỳ Thảm họa @:shared.eventName bắt đầu từ @:shared.eventStartDate đến @:shared.eventEndDate .",
    },
    homePage: {
      title: "Công cụ ước tính phúc lợi",
      subtitle: "Các công cụ ước tính phúc lợi. Bắt đầu ước tính phúc lợi DSNAP của bạn.",
      getStarted: "Bắt đầu",
      getStartedBody:
        "Công cụ ước tính phúc lợi chỉ có thể ước tính và không đảm bảo chắc chắn sẽ nhận được phúc lợi. Bạn phải gửi đơn đăng ký cho chương trình được chỉ định để xác định tính bạn có đủ điều kiện hay không. Các hộ gia đình phải đáp ứng các bài kiểm tra nhất định, bao gồm các bài kiểm tra về nguồn lực và thu nhập để đủ điều kiện nhận các phúc lợi của chương trình.",
      dsnapButtonText: "Ước tính phúc lợi của DSNAP",
    },
    dsnapPage: {
      title: "Công cụ ước tính phúc lợi DSNAP",
      subtitle:
        "Chương trình Hỗ trợ Dinh dưỡng Bổ sung sau Thiên tai (DSNAP) cung cấp cho các hộ gia đình hợp chuẩn, những người thường không nhận được các khoản phúc lợi Hỗ trợ Dinh dưỡng Bổ sung (SNAP), một số tiền để hỗ trợ mua hàng tạp hóa và thực phẩm phụ trong tình trạng mất thu nhập hoặc chịu thiệt hại sau một thảm họa.",
      householdTitle: "Thông tin hộ gia đình",
      incomeInputLabel: "Thu nhập",
      incomeInputDescription:
        "Hãy cho biết số tiền thu nhập thực tế mà bạn từng nhận hay dự kiến nhận được trong thời kỳ áp dụng phúc lợi sau thảm họa 30 ngày. Thu nhập thực tế của bạn bao gồm các khoản lương từ công việc (kể cả công việc tự kinh doanh) và thu nhập từ các nguồn khác, ví dụ như từ các chương trình hỗ trợ công.",
      incomeValidationRequired: "Thu nhập là bắt buộc.",
      incomeValidationMoreThan: "Thu nhập là bắt buộc.",
      incomeHelpText:
        "Các khoản lương thực tế là số tiền mà bạn và gia đình của bạn thực sự nhận được, hay dự kiến nhận được, sau các khoản khấu trừ tự động cho các mục như thuế, phí bảo hiểm, góp vào chương trình hưu trí, v.v... Nói cách khác, các khoản lương thực tế của bạn là số tiền được chuyển vào tài khoản ngân hàng của bạn hoặc được in trên chi phiếu của bạn.",
      resourcesInputLabel: "Tài nguyên có thể truy cập",
      resourcesInputDescription:
        "Hãy cho biết số tiền mà bạn hiện có sẵn, bao gồm tiền mặt đang nắm giữ và các khoản tiền trong tài khoản vãng lai, tài khoản tiết kiệm hay tài khoản thị trường tiền tệ của bạn, v.v… KHÔNG bao gồm số tiền có trong các tài khoản hưu trí của bạn.",
      resourcesValidationRequired: "Tài nguyên là bắt buộc.",
      resourcesValidationMoreThan: "Tài nguyên là bắt buộc.",
      expensesInputLabel: "Chi phí liên quan đến thiên tai",
      expensesInputDescription:
        "Hãy cho biết số tiền mà bạn đã tiêu tốn hay dự kiến tiêu tốn cho các khoản chi phí có liên quan đến thảm họa mà không được bồi hoàn trong thời kỳ áp dụng phúc lợi sau thảm họa là 30 ngày.",
      expensesValidationRequired: "Các khoản chi là bắt buộc.",
      expensesValidationMoreThan: "Các khoản chi là bắt buộc.",
      expensesHelpText:
        "Ví dụ về các khoản chi phí có liên quan đến thảm họa có thể bao gồm các khoản chi phí sửa chữa nhà cửa hay cơ sở kinh doanh, chi phí nơi ở tạm thời, chi phí tản cư, các khoản chi phí có liên quan đến việc thay thế các vật dụng cần thiết cho cá nhân và gia đình như quần áo, thiết bị và công cụ gia dụng, nhiên liệu dùng cho nguồn sưởi chính, chi phí mua các vật dụng dọn dẹp vệ sinh, chi phí cho xe cộ bị hư hỏng vì thảm họa, tổn thất thực phẩm do thảm họa hay mất điện, các chi phí lưu trữ và di chuyển trong thời kỳ 30 ngày, v.v... Các hạng mục như thế chấp, tiền thuê, tiền trả nợ xe hàng tháng, v.v.. là các khoản chi phí tái tục định kỳ và không được tính là chi phí có liên quan đến thảm họa.",
      householdInputLabel: "Quy mô hộ gia đình",
      householdInputDescription:
        'Một "hộ" là số người mà bạn cùng sống, cùng chia sẻ chi phí và bữa ăn với bạn.',
      householdValidationRequired: "Quy mô hộ gia đình là bắt buộc.",
      householdValidationMoreThan: "Quy mô hộ gia đình là bắt buộc và phải lớn hơn 0.",
      calculateButton: "Tính toán ước tính",
      recalculateButton: "Tính toán lại ước tính",
      benefitEstimateTitle: "Ước tính phúc lợi",
      eligibilityStatement:
        "Tiêu chuẩn hợp cách để hưởng các phúc lợi theo chương trình DSNAP được xem xét theo thu nhập, các nguồn tài lực và các khoản chi phí có liên quan đến thảm họa của bạn trong thời kỳ thảm họa được ấn định là 30 ngày. Trước tiên, chúng tôi tính mức thu nhập thực tế của bạn (từ công việc và các nguồn khác như Cấp dưỡng Nuôi con, cấp dưỡng cho người hôn phối, trợ cấp thất nghiệp, phúc lợi An sinh Xã hội, các khoản phúc lợi theo chương trình Trợ cấp An sinh Bổ sung (SSI), các khoản phúc lợi Cựu chiến binh (VA), các khoản phúc lợi theo Chương trình Trợ cấp Tạm thời cho Gia đình Tự túc (FITAP), đền bù cho người lao động, các khoản hưu trí, các khoản đóng góp từ bạn bè và/hay gia đình, v.v...) Sau đó, chúng tôi cộng với các nguồn tiền mặt hiện có của bạn  (bao gồm các số dư trong tài khoản vãng lai và tài khoản tiết kiệm) và trừ tổng số tiền mà bạn đã tiêu tốn cho bất cứ chi phí có liên quan đến thảm họa nào chưa từng được bồi hoàn - trong thời kỳ được ấn định là 30 ngày ấy.",
      estimateDisclaimer:
        "Xin lưu ý: Đây là một con số dự toán chỉ dựa trên các thông tin được cung cấp bên dưới đây. Đây không phải là một đảm bảo cho các khoản phúc lợi hay số tiền mà bạn có thể nhận được nếu bạn được xét là đủ tiêu chuẩn hợp cách sau khi nộp đơn xin phúc lợi theo chương trình DSNAP.",
      householdWarningMessage:
        "Vui lòng điền vào tất cả các thông tin hộ gia đình để tính toán phúc lợi ước tính của bạn.",
      ineligibleMessage:
        "Bạn <strong>có thể không đủ điều kiện</strong> nhận các phúc lợi DSNAP dựa trên thu nhập đã điều chỉnh của bạn vượt quá <strong>giới hạn tổng thu nhập trong thời kỳ thiên tai</strong>.",
      eligibleMessage:
        "Bạn <strong>có thể đủ điều kiện</strong> nhận các phúc lợi DSNAP với mức phân bổ ước tính là <strong>{maxAllotment}</strong>.",
      visitDsnapMessage:
        'Vui lòng <a href="http://www.dcfs.la.gov/DSNAP" target="_blank">truy cập</a> DSNAP để đăng ký trước trực tuyến.',
      resultTitle: "Làm thế nào tôi có được kết quả này?",
      resultStepOneMessage:
        "Đầu tiên, chúng tôi tính toán tổng thu nhập của bạn bằng cách cộng thu nhập bạn đã cung cấp, <strong>{income}</strong> đô la với các tài nguyên bạn có quyền truy cập, <strong>{resources}</strong> đô la để có tổng thu nhập là <strong>{totalIncome}</strong> đô la.",
      resultStepTwoMessage:
        "Sau đó, chúng tôi tính toán thu nhập đã điều chỉnh của bạn bằng cách trừ đi các chi phí liên quan đến thiên tai chưa được hoàn trả, <strong>{expenses}</strong> từ tổng thu nhập <strong>{totalIncome}</strong> của bạn để tính số tiền thu nhập đã điều chỉnh của bạn là <strong>{adjustedIncome}</strong>.",
      resultEligibleMessage:
        "Cuối cùng, chúng tôi đã so sánh thu nhập đã điều chỉnh của bạn, <strong>{adjustedIncome}</strong> với giới hạn thu nhập cho quy mô hộ gia đình gồm <strong>{householdSize} người</strong> là <strong>{incomeLimit}</strong>. Bởi vì thu nhập đã điều chỉnh của bạn nhỏ hơn giới hạn thu nhập cho quy mô hộ gia đình của bạn, phân bổ tối đa cho hộ gia đình của bạn là <strong>{maxAllotment}</strong>.",
      resultIneligibleMessage:
        "Cuối cùng, chúng tôi so sánh thu nhập đã điều chỉnh của bạn, <strong>{adjustedIncome}</strong> với giới hạn thu nhập cho quy mô hộ gia đình gồm <strong>{householdSize} người</strong> là <strong>{incomeLimit}</strong>. Vì thu nhập đã điều chỉnh của bạn vượt quá giới hạn tổng thu nhập cho thảm họa, đó là lý do tại sao chúng tôi ước tính rằng bạn có khả năng <strong>không đủ điều kiện</strong>.",
    },
  },
};

let i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  legacy: false,
  globalInjection: true,
  warnHtmlInMessage: false,
  messages,
});

export { i18n as default };
